<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-form ref="shareholderCreate">
    <SelfBuildingSpinner v-if="loading" />
    <div v-else>
      <v-row class="px-10">
        <v-col v-if="isIntoClientStep" cols="4">
          <v-combobox
            v-model="name"
            :append-icon="null"
            :items="shareholders"
            :loading="shareholdersFetching"
            :search-input.sync="search"
            color="red darken-4"
            dense
            hide-no-data
            outlined
            item-text="name"
            item-value="id"
            label="Name"
            return-object
            :error-messages="requiredError($v.name, 'Client Name')"
            @change="selectExistingShareholder"
            @blur="$v.name.$touch"
          ></v-combobox>
        </v-col>
        <v-col v-else cols="4">
          <v-text-field
            v-model="name"
            color="red darken-4"
            outlined
            dense
            label="Name"
            :error-messages="requiredError($v.name, 'Client Name')"
            @change="$v.name.$touch"
            @blur="$v.name.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="passport"
            color="red darken-4"
            outlined
            dense
            label="Passport/Id number"
            :error-messages="requiredError($v.passport, 'Passport/Id number')"
            @change="$v.passport.$touch"
            @blur="$v.passport.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="nationality"
            color="red darken-4"
            outlined
            dense
            label="Nationality"
            :error-messages="requiredError($v.nationality, 'Nationality')"
            @change="$v.nationality.$touch"
            @blur="$v.nationality.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="residenceCountry"
            color="red darken-4"
            outlined
            dense
            mandatory
            :items="countryList"
            prepend-icon="mdi-map"
            label="Residence Country"
            :error-messages="
              requiredError($v.residenceCountry, 'Residence country')
            "
            @change="$v.residenceCountry.$touch"
            @blur="$v.residenceCountry.$touch"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="email"
            color="red darken-4"
            outlined
            dense
            label="Email"
            :error-messages="emailErrors"
            @change="$v.email.$touch"
            @blur="$v.email.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="mobileNumber"
            :error-messages="phoneErrors"
            color="red darken-4"
            outlined
            dense
            label="Mobile Number"
            @change="$v.mobileNumber.$touch"
            @blur="$v.mobileNumber.$touch"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="address"
            color="red darken-4"
            outlined
            dense
            label="Address"
            :error-messages="requiredError($v.address, 'Address')"
            @change="$v.address.$touch"
            @blur="$v.address.$touch"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="remarks"
            outlined
            color="red darken-4"
            auto-grow
            label="Remarks"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>Mandatory documents to add:</p>
          <v-chip-group active-class="primary--text" column>
            <v-chip
              v-for="(docType, index) in mandatoryDocumentTypes"
              :key="`mandatory-doc-type-${index}`"
              :color="
                hasDocumentInserted(docType)
                  ? 'green darken-4'
                  : 'grey lighten-4'
              "
              :class="
                hasDocumentInserted(docType) ? 'white--text' : 'black--text'
              "
              small
              >{{ docType }}</v-chip
            >
          </v-chip-group>
        </v-col>
        <v-col v-if="documents.length !== 0" cols="12">
          <div
            v-for="(document, index) in documents"
            :key="`shareholder-document-${index}`"
          >
            <v-row justify="center">
              <v-spacer></v-spacer>
              <v-col :cols="colSize(document)">
                <v-select
                  v-model="document.type"
                  item-text="label"
                  item-value="type"
                  color="red darken-4"
                  outlined
                  dense
                  mandatory
                  :items="documentList"
                  label="Document Type"
                  :error-messages="
                    requiredError(
                      $v.documents.$each[index].type,
                      'Document Type',
                    )
                  "
                  @change="$v.documents.$each[index].type.$touch"
                  @blur="$v.documents.$each[index].type.$touch"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      :disabled="item.selected"
                      :attrs="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="document.type === 'passport'" cols="3">
                <v-text-field
                  v-model="document.number"
                  outlined
                  dense
                  color="red darken-4"
                  label="Document Number"
                  :error-messages="
                    requiredError(
                      $v.documents.$each[index].number,
                      'Document Number',
                    )
                  "
                  @change="$v.documents.$each[index].number.$touch"
                  @blur="$v.documents.$each[index].number.$touch"
                ></v-text-field>
              </v-col>
              <v-col v-if="document.type === 'passport'" cols="3">
                <v-menu
                  ref="menuDocumentExpiry"
                  v-model="document.menu"
                  :close-on-content-click="false"
                  :return-value.sync="document.menu"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="document.expiryDate"
                      color="red darken-4"
                      outlined
                      dense
                      label="Expiry Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="
                        requiredError(
                          $v.documents.$each[index].expiryDate,
                          'Expiry Date',
                        )
                      "
                      @change="$v.documents.$each[index].expiryDate.$touch"
                      @blur="$v.documents.$each[index].expiryDate.$touch"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="document.expiryDate"
                    no-title
                    :allowed-dates="allowedDates"
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      color="red darken-4"
                      @click="document.menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="red darken-4"
                      @click="
                        $refs.menuDocumentExpiry[index].save(
                          document.expiryDate,
                        )
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="colSize(document)">
                 <div class="col-4-drag-drop">
                <img :src="image" alt="" class="image-class" cols="12" />
                 <vue-dropzone id="drop1"
                  ref="dropzone"
                  class="drag-drop-image"
                  :options="dropOptions"
                  :include-styling="false"
                  :use-custom-slot="true"
                  @vdropzone-complete="afterComplete"
                  @vdropzone-removed-file="removeThisFile"
                  ></vue-dropzone>
                </div>
              </v-col>
              <v-col cols="1">
                <v-btn
                  icon
                  color="red darken-4"
                  dark
                  @click="deleteDocument(index)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col v-if="document.type === 'other'" cols="12">
                <v-row no-gutters>
                  <v-spacer></v-spacer>
                  <v-col cols="8">
                    <v-textarea
                      v-model="document.comment"
                      outlined
                      dense
                      color="red darken-4"
                      auto-grow
                      label="Comment"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn v-if="isIntoClientStep" color="red darken-4" dark @click="back"
            >Back</v-btn
          >
          <v-btn
            v-if="!documentsFull"
            color="red darken-4"
            :class="isIntoClientStep ? 'ml-2' : ''"
            dark
            @click="addDocument"
            ><v-icon class="mr-2">mdi-file-document</v-icon>Add a document</v-btn
          >
          <v-btn
            v-if="allMandatoryInserted && isIntoClientStep"
            color="teal lighten-1"
            dark
            class="ml-2"
            @click="saveShareholder"
            ><v-icon class="mr-2">mdi-content-save</v-icon>Save Shareholder</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
import { getNames } from 'country-list'
import { validationMixin } from 'vuelidate'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import {
  mobilePhoneValidator,
  validateError,
  validExtension,
} from '@utils/validateFields'
import EventBus from '@src/utils/EventBus'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import {
  EventBusEvents,
  ClientTypesFull,
  documentTypes,
  MandatoryIndividualShareholderFields,
} from '@src/constants/index.js'
import isAfter from 'date-fns/isAfter'
import isToday from 'date-fns/isToday'
import api from '@src/api/index.js'
import vueDropzone from 'vue2-dropzone'
const imageURL = require('../../assets/cloud.png')

const getTemplate = () => `
<div class="dz-preview dz-file-preview">
  <div class="dz-image">
    <div data-dz-thumbnail-bg></div>
  </div>
  <div class="dz-details">
    <div class="dz-size"><span data-dz-size></span></div>
    <div class="dz-filename"><span data-dz-name></span></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
</div>
`;

export default {
  name: 'ShareholderIndividualStep',
  components: {
    SelfBuildingSpinner,
    vueDropzone
  },
  mixins: [validationMixin],
  props: {
    isIntoClientStep: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      address: { required },
      name: { required },
      email: {
        required,
        email,
      },
      passport: {
        required,
      },
      mobileNumber: {
        required,
        mobilePhoneValidator,
      },
      residenceCountry: { required },
      nationality: { required },
      documents: {
        $each: {
          type: { required },
          number: {
            required: requiredIf(({ type }) => type === 'passport'),
          },
          expiryDate: {
            required: requiredIf(({ type }) => type === 'passport'),
          },
          file: {
            required: requiredIf(({ type }) =>
              MandatoryIndividualShareholderFields.includes(type)
            ),
            validExtension,
          },
        },
      },
    }
  },
  data() {
    return {
      address: '',
      email: '',
      search: null,
      shareholdersFetching: false,
      autocompleteValue: null,
      mobileNumber: '',
      name: '',
      passport: '',
      loading: false,
      shareholders: [],
      nationality: '',
      remarks: '',
      residenceCountry: '',
      documents: [],
      individualDocuments: [
        {
          label: 'Passport/Id card',
          type: 'passport',
        },
        {
          label: 'Bank/Professional Reference',
          type: 'reference',
        },
        {
          label: 'Proof of residence',
          type: 'residenceProof',
        },
        {
          label: 'Compliance Check',
          type: 'complianceCheck',
        },
        {
          label: 'Personal Questionnaire',
          type: 'personalQuestionnaire',
        },
        {
          label: 'Source of wealth',
          type: 'sourceWealth',
        },
        {
          label: 'Source of funds',
          type: 'sourceFunds',
        },
        {
          label: 'Vat certificate',
          type: 'vatCertificate',
        },
        {
          label: 'Other',
          type: 'other',
        },
      ],
       dropOptions: {
        url: 'https://httpbin.org/post',
        maxFilesize: 2, // MB
        maxFiles: 20,
        chunking: false,
        chunkSize: 500, // Bytes
        thumbnailWidth: 75, // px
        thumbnailHeight: 75,
        addRemoveLinks: true,
        previewTemplate: getTemplate()
      },
      image: imageURL,
    }
  },
  computed: {
    countryList() {
      return getNames()
    },
    documentsFull() {
      return this.documents.length === this.individualDocuments.length
    },
    mandatoryDocumentTypes() {
      return MandatoryIndividualShareholderFields.map(
        (doc) => documentTypes[doc]
      )
    },
    allMandatoryInserted() {
      return MandatoryIndividualShareholderFields.every((mandatoryDocument) => {
        return this.documents.some(({ type }) => type === mandatoryDocument)
      })
    },
    emailErrors() {
      return validateError(
        this.$v.email,
        ['required', 'email'],
        ['Email is required', 'Email is invalid']
      )
    },
    phoneErrors() {
      return validateError(
        this.$v.mobileNumber,
        ['required', 'mobilePhoneValidator'],
        ['Mobile number is required', 'Mobile number is invalid']
      )
    },
    documentList() {
      const documentsInserted = this.documents.map(({ type }) => type)

      return this.individualDocuments
        .map(({ label, type }) => ({
          label,
          type,
          selected: documentsInserted.includes(type),
        }))
        .sort((a, b) => Number(a.selected) - Number(b.selected))
    },
  },
  watch: {
    search(newVal) {
      if (newVal && typeof newVal === 'string' && newVal.length > 1) {
        if (this.shareholdersFetching) return
        this.fetchShareholders({
          shareholderName: newVal,
          shareholderType: ClientTypesFull.INDIVIDUAL,
        })
      }
    },
  },
  beforeDestroy() {
    EventBus.$off(
      EventBusEvents.ON_SAVE_CLICK_NEW_SHAREHOLDER,
      this.saveShareholder,
      this
    )
  },
  mounted() {
    EventBus.$on(
      EventBusEvents.ON_SAVE_CLICK_NEW_SHAREHOLDER,
      this.saveShareholder,
      this
    )
  },
  methods: {
    colSize({ type }) {
      return type === 'passport' ? 2 : 4
    },
    hasDocumentInserted(docType) {
      return this.documents.some(({ type }) => docType === documentTypes[type])
    },
    selectExistingShareholder(shareholder) {
      this.$v.name.$touch()

      if (shareholder && typeof shareholder === 'object') {
        EventBus.$emit(
          EventBusEvents.ON_SHAREHOLDER_SELECTED,
          shareholder,
          this
        )
        this.resetAccordion()
      }
    },
    fileErrors(file) {
      return validateError(
        file,
        ['required', 'validExtension'],
        ['File is required', 'Extension wrong, accepted: png, jpg, pdf']
      )
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    allowedDates(val) {
      return (
        isToday(new Date(val), new Date()) || isAfter(new Date(val), new Date())
      )
    },
    addDocument() {
      this.documents.push({
        type: '',
        label: '',
        menu: '',
        number: '',
        comment: '',
        expiryDate: '',
        file: null,
      })
    },
    deleteDocument(index) {
      this.documents.splice(index, 1)
    },
    async fetchShareholders({ shareholderType, shareholderName }) {
      this.shareholdersFetching = true
      try {
        const shareholders = await api.getShareholderByNameAndType({
          shareholderName,
          shareholderType,
        })
        this.shareholders = shareholders
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.shareholdersFetching = false
      }
    },
    async saveShareholderClient(payload) {
      this.loading = true
      try {
        const shareholder = await api.createShareholder(payload)
        this.loading = false
        return this.saveShareholderDocuments(shareholder)
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async saveShareholderDocuments(shareholder) {
      this.loading = true
      const { clientId, name } = shareholder
      try {
        const documents = await api.createShareholderDocuments({
          clientId,
          name,
          documents: this.documents,
          shareholderType: ClientTypesFull.INDIVIDUAL,
          forceErrorMsg: true,
        })

        if (this.isIntoClientStep) {
          this.loading = false
          EventBus.$emit(
            EventBusEvents.ON_SHAREHOLDER_SELECTED,
            {
              ...shareholder,
              documents,
            },
            this
          )
          return this.resetAccordion()
        }
        this.$router.push({ name: 'shareholders' })
      } catch (err) {
        this.loading = false

        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'An error occurred',
          message: err,
          activeCancel: false,
          confirmAction: () => this.documentError(),
        })
      }
    },
    documentError() {
      if (this.isIntoClientStep) {
        this.resetAccordion()
        return
      }
      this.$router.push({ name: 'shareholders' })
    },
    resetAccordion() {
      this.$refs.shareholderCreate.reset()
      this.$v.$reset()
      EventBus.$emit(EventBusEvents.RESET_CREATE_SHAREHOLDER_ACCORDION, this)
    },
    back() {
      EventBus.$emit(
        EventBusEvents.RESET_CREATE_SHAREHOLDER_ACCORDION,
        {},
        this
      )
    },
    saveShareholder() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        const err = {
          message: 'Field validation failed. Check all fields inserted.',
        }
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
        return
      }

      // if (!this.allMandatoryInserted) {
      //   const err = { message: 'You should insert all required documents!' }
      //   EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      //   return
      // }
      const shareholderInfoPayload = {
        name: this.name,
        passport: this.passport,
        nationality: this.nationality,
        email: this.email,
        address: this.address,
        shareholderType: ClientTypesFull.INDIVIDUAL,
        mobileNumber: this.mobileNumber,
        residenceCountry: this.residenceCountry,
        remarks: this.remarks,
      }

      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Confirm',
        message: 'Are you sure to save this shareholder?',
        confirmAction: () => this.saveShareholderClient(shareholderInfoPayload),
      })
    },
    afterComplete(file) {
      this.documents.forEach(element => {
        if(element.file === null) {
            element.file = file;
        }
      });
    },
    removeThisFile(file) {
      this.documents.forEach(element => {
        if(element.file.name === file.name) {
            element.file = null;
        }
      });
    },
  },
}
</script>
<style>
.col-4-drag-drop {
    border: darkgrey 2px dotted;
    height: auto;
    border-radius: 5px;
    padding: 16px;
}
.drag-drop-image {
    margin-left: 100px;
    margin-top: -30px;
}
.image-class {
    height: 30px;
    margin-left: 60px;
    margin-top: 1px;
}
</style>
